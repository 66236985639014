import React from "react"
import { css } from "@emotion/core"

import Layout from "../components/layout"
import ContactForm from "../components/ContactForm"

function packagesContact({ data, location }) {
  return (
    <Layout>
      <div
        className="sectionContent"
        css={css`
          max-width: 800px;
        `}
      >
        <ContactForm location={location.pathname} />
      </div>
    </Layout>
  )
}

export default packagesContact
